<template>
    <topBanner/>
    <aboutus/>
</template>

<script>
import topBanner from '../components/innerpagestopbanner/innerTop.vue'
import aboutus from '../components/aboutus.vue' 
export default {
    components:{
        topBanner,
        aboutus
    },
    data(){
        return{}
    }
}
</script>
